import React, { Component } from "react";

import { FormGroup, Grid, Row, Col, Button, Alert } from "react-bootstrap";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle
} from "react-google-maps";
import { AutoAffix } from "react-overlays";
import { AdForm, AdPreview, ShowAlert, LoadingScreen } from "./components.js";
import Conf from "../../config.js";

import "./create.css";

const initialCoords = { lat: 51.234, lng: 4.3833 };

export default class Create extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {
        lang: "en",
        enabled: true,
        days: [],
        title: "",
        text: "",
        conditions: "",
        address: "",
        radius: "",
        link: "",
        onlyOnce: false,
        priority: '3',
        state: 'draft',
        hideButton: false,
        coords: JSON.stringify(initialCoords),
        forNewUsers: false,
       },
      subMessages: [],
      logoUrl: "",
      imageUrl: "",
      coords: { lat: 51.234, lng: 4.3833 },
      loading: false,
    };
  }

  addMessages = () => {
    this.setState((prevState) => ({
      subMessages: [...prevState.subMessages, { text: '', title: '' }]
    }));
  }

  txtChanged = (e) => {
    if (['text', 'title'].includes(e.target.name) ) {
      let subMessages = [...this.state.subMessages]
      subMessages[e.target.dataset.id][e.target.name] = e.target.value
      this.setState({ subMessages })
    }
  }

  handleImageChange = (e, field, index) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let newSubMessages = [...this.state.subMessages]
      newSubMessages[index][field] = file;
      newSubMessages[index][field + "Url"] = reader.result

      this.setState({ subMessages: newSubMessages });
    };

    if (!file) return;
    reader.readAsDataURL(file);

    if (field === "image") {
      this.setState({ imgError: false });

      let img = new Image();
      let parent = this;
      img.onload = () => {
        let r = img.width / img.height;
        if (r < 1.9 || r > 1.92) {
          parent.setState({ imgRatio: r, imgError: true });
        }
      };

      let _URL = window.URL || window.webkitURL;
      img.src = _URL.createObjectURL(file);
    }
  }

  getJWTToken() {
    return localStorage.getItem("token");
  }

  formIsValid = () => {
    const { subMessages, form } = this.state;
    if (!form.title || !form.address || !form.days.length || !form.hours ) return false;
    for (const message of subMessages) {
      const { text, image, title } = message;
      if (!text || !image || !title) return false;
    }
    return true;
  }

  sendAll = async (event) => {
    event.preventDefault();
    const { history } = this.props;
    this.setState({loading: true});
    if (!this.formIsValid()) {
      this.setState({ error: true, errorMessage: 'All fields must be filled', loading: false });
      return;
    }
    let formData = new FormData();
    const { form, subMessages } = this.state;
    let obj = form;
    obj.showInMenu = true;
    for (let name in obj) {
      formData.append(name, obj[name]);
    }
    this.setState({ error: false });
    try {
      const json = await this.send(formData);
      let previousId = json._id;
      for (const message of subMessages) {
        let newFormdata = new FormData();
        const { text, title, image } = message;
        let newObj = {...obj, text, image, title };
        newObj.previousId = previousId
        newObj.showInMenu = false;
        for (let name in newObj) {
          newFormdata.append(name, newObj[name]);
        }
        const newJson = await this.send(newFormdata);
        previousId = newJson._id;
      }
      // if (json && json._id) return this.setState({ success: true });
      this.setState({ success: true, loading: false });
      history.push('/list');
    } catch (err) {
      console.log(err);
      this.setState({ error: true, errorMessage: err.message || err.error, success: false, loading: false });
    }
  }

  send = async (formData) => {
    const server = await localStorage.getItem('env');
    this.setState({ error: false });
    try {
      const res = await fetch(server + "ads/", {
        method: "POST",
        headers: {
          Authorization: "JWT " + this.getJWTToken()
        },
        body: formData
      })
      const data = await res.json();
      if (data && data.error) throw data;
      return data;
    } catch (err) {
      console.log(err)
      throw { error: err.error.length ? err.error : 'Error with server' }
    }
  }

  deleteSlide = (index) => {
    const { subMessages } = this.state;
    const newSubMessages = [...subMessages];
    newSubMessages.splice(index, 1);
    this.setState({ subMessages: newSubMessages })
  }

  render() {
    const { form, subMessages, success, error, loading, errorMessage } = this.state;
    return (
      <div className="container-fluid position-relative">
        <div className="row justify-content-between mb-5">
            {loading && <LoadingScreen />}
            <ShowAlert success={success} error={error} errorMessage={errorMessage} />
            <AdForm
              form={form}
              subMessages={subMessages}
              addMessages={this.addMessages}
              onFormChange={form => {
                this.setState({ form });
              }}
              onCoordsChange={coords => {
                this.setState({ coords });
              }}
              onImgChange={urls => {
                this.setState(urls);
              }}
              txtChanged={this.txtChanged}
              handleImageChange={this.handleImageChange}
              deleteSlide={this.deleteSlide}
            />
          <div className="col-md-3 col-12">
              <div id="previewBlock">
                <h3>Preview of the Area</h3>
                {this.state.coords ? (
                  <MapPreview
                    coords={this.state.coords}
                    radius={this.state.form.radius}
                    isMarkerShown={this.state.coords}
                    googleMapURL={
                      "https://maps.googleapis.com/maps/api/js?key=" +
                      Conf.mapsKey
                    }
                    loadingElement={
                      <div style={{ height: `100%`, width: "100%" }} />
                    }
                    containerElement={
                      <div style={{ height: `120px`, width: "100%" }} />
                    }
                    mapElement={
                      <div style={{ height: `100%`, width: "100%" }} />
                    }
                  />
                ) : null}

                <h3>Preview of your ad</h3>
                <AdPreview
                  ad={this.state.form}
                  logoUrl={this.state.logoUrl}
                  imageUrl={this.state.imageUrl}
                  carouselSlides={subMessages}
                />
                
                <div className="form-group row">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-primary w-100" onClick={this.sendAll}>Save</button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapPreview = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap zoom={11} center={props.coords}>
      {props.isMarkerShown && <Marker position={props.coords} />}
      {props.isMarkerShown && props.radius && (
        <Circle
          options={{
            strokeColor: "#0000FF",
            strokeOpacity: 0.7,
            strokeWeight: 1,
            fillColor: "#0000FF",
            fillOpacity: 0.35
          }}
          center={props.coords}
          radius={props.radius ? parseInt(props.radius, 10) : 0}
        />
      )}
    </GoogleMap>
  ))
);
