import React, { Component } from "react";

import { Grid, Row, Col, Button, Alert, ButtonToolbar } from "react-bootstrap";

export default class Topup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      credits: ""
    };
  }

  componentWillMount() {
    this.getBalance();
  }

  getJWTToken() {
    return localStorage.getItem("token");
  }

  sendMoney = async (event, amount) => {
    event.preventDefault();
    this.setState({ error: false });
    const server = await localStorage.getItem('env');
    fetch(server + "ads/topup", {
      method: "POST",
      headers: {
        Authorization: "JWT " + this.getJWTToken(),
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ amount: amount })
    })
      .then(response => response.json())
      .then(json => {
        if (json && !json.message)
          return this.setState({ success: true, credits: json.amount });

        return this.setState({
          error: true,
          errorMessage: json.message,
          success: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: true, errorMessage: err.error, success: false });
      });
  }

  getBalance = async () => {
    const server = await localStorage.getItem('env');
    fetch(server + "ads/balance", {
      method: "GET",
      headers: {
        Authorization: "JWT " + this.getJWTToken()
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json && !json.message)
          return this.setState({ credits: json.amount });

        return this.setState({
          error: true,
          errorMessage: json.message,
          success: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: true, errorMessage: err.error, success: false });
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-8" />
          <div className="col-sm-4">
            {this._renderAlerts()}
            Choose one of the following package of credits to topup your
            account:
            <div className="btn-toolbar" role="toolbar" aria-label="Toolbar with button groups">
              <div className="btn-group mr-2" role="group" aria-label="First group">
              <button
                type="button"
                className="btn btn-secondary"
                onClick={e => {
                  this.sendMoney(e, 50);
                }}
              >
                50
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={e => {
                  this.sendMoney(e, 100);
                }}
              >
                100
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={e => {
                  this.sendMoney(e, 200);
                }}
              >
                200
              </button>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={e => {
                  this.sendMoney(e, 500);
                }}
              >
                500
              </button>
              </div>
            </div>
            Total of credits on your account:{" "}
            <strong>{this.state.credits || "0"}</strong>
          </div>
        </div>
      </div>
    );
  }

  _renderAlerts() {
    return (
      <>
        {this.state.error ? (
          <div className="alert alert-danger" role="alert">
            An error happened: {this.state.errorMessage}
          </div>
        ) : null}

        {this.state.success ? (
          <div className="alert alert-success" role="alert">
            Success, the ad is now created and will be reviewed by our team.
            You\'ll receive an email when your ad is approved.
          </div>
        ) : null}
      </>
    );
  }
}
