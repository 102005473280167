let Conf = {
  server: "https://api.cparkapp.com/",
  mapsKey: "AIzaSyDj4aVI4V20aqUxpVFDljQF5u62wwN2bcI"
};

//if(process.env.DEBUG) {
if (window.location.href.indexOf("localhost") > -1) {
  Conf.server = "http://localhost:4700/";
}

//if(process.env.PREPROD) {
if (window.location.href.indexOf("beta.cparkapp.com") > -1) {
  Conf.server = "https://api.beta.cparkapp.com/";
}

export default Conf;
