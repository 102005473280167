import React, { Component } from "react";

import { FormGroup, Grid, Row, Col, Button, Alert } from "react-bootstrap";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  Circle
} from "react-google-maps";
import { AutoAffix } from "react-overlays";
import { AdForm, AdPreview, ShowAlert, LoadingScreen } from "./components.js";
import Conf from "../../config.js";

import "./create.css";

export default class Edit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { lang: "fr", enabled: true },
      subMessages: [],
      logoUrl: "",
      imageUrl: "",
      coords: { lat: 51.234, lng: 4.3833 },
      id: props.match.params.id,
      loading: false,
    };
  }

  getJWTToken() {
    return localStorage.getItem("token");
  }

  componentWillMount() {
    this.get(this.state.id);
  }

  get = async (id) => {
    const server = await localStorage.getItem('env');
    fetch(server + "ads/" + id, {
      method: "GET",
      headers: {
        Authorization: "JWT " + this.getJWTToken()
      }
    })
      .then(response => response.json())
      .then(json => {
        const { ad } = json;
        if (ad && ad._id) {
          let hours = "";
          ad.hours.map(e => {
            return (hours += e + ", ");
          });
          ad.hours = hours;

          ad.coords = JSON.stringify({
            lat: ad.coords[1],
            lng: ad.coords[0]
          });

          return this.setState({
            form: ad,
            subMessages: ad.nextMessages,
            logoUrl: ad.logo.replace("public/", server),
            imageUrl: ad.image.replace("public/", server),
            coords: JSON.parse(ad.coords),
          });
        }
        return this.setState({
          error: true,
          errorMessage: ad.message,
          success: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: true, errorMessage: err.error, success: false });
      });
  }

  formIsValid = () => {
    const { subMessages, form } = this.state;
    if (!form.title || !form.address || !form.days.length || !form.hours ) return false;
    for (const message of subMessages) {
      const { text, image, title } = message;
      if (!text || !image || !title) return false;
    }
    return true;
  }

  sendAll = async (event) => {
    event.preventDefault();
    this.setState({loading: true})
    if (!this.formIsValid()) {
      this.setState({ error: true, errorMessage: 'All fields must be filled', loading: false });
      return;
    }
    let formData = new FormData();
    const { form, subMessages } = this.state;
    let obj = form;
    for (let name in obj) {
      formData.append(name, obj[name]);
    }
    this.setState({ error: false });
    try {
      const json = await this.send(formData, obj._id);
      let previousId = json._id;
      for (const message of subMessages) {
        let newFormdata = new FormData();
        const { text, title, image } = message;
        let newObj = message;
        if (!newObj._id) {
          const { _id, ...rest} = obj;
          newObj = {...rest, text, image, title };
        }
        newObj.previousId = previousId
        newObj.showInMenu = false;
        newObj.coords = obj.coords;
        for (let name in newObj) {
          newFormdata.append(name, newObj[name]);
        }
        let newJson;
        if (newObj._id) newJson = await this.send(newFormdata, newObj._id);
        else newJson = await this.sendCreate(newFormdata);
        previousId = newJson._id;
      }
      // if (json && json._id) return this.setState({ success: true });
      this.setState({ success: true, loading: false });
    } catch (err) {
      console.log(err);
      this.setState({ error: true, errorMessage: err.error || err.message, success: false, loading: false });
    }
  }

  sendCreate = async (formData) => {
    const server = await localStorage.getItem('env');
    this.setState({ error: false });
    try {
      const res = await fetch(server + "ads/", {
        method: "POST",
        headers: {
          Authorization: "JWT " + this.getJWTToken()
        },
        body: formData
      })
      const data = await res.json();
      if (data && data.error) throw data;
      return data;
    } catch (err) {
      throw { error: err.error.length ? err.error : 'Error with server' }
    }
  }

  send = async (formData, id) => {
    this.setState({ error: false });
    try {
      const server = await localStorage.getItem('env');
      const res = await fetch(server + "ads/" + id, {
        method: "PUT",
        headers: {
          Authorization: "JWT " + this.getJWTToken()
          //	"Content-Type": "application/json"
        },
        body: formData
      })
      const data = await res.json();
      if (data && data.error) throw data;
      return data;
    } catch (err) {
      throw { error: err.error.length ? err.error : 'Error with server' }
    }
  }

  addMessages = () => {
    this.setState((prevState) => ({
      subMessages: [...prevState.subMessages, { text: '' }]
    }));
  }

  txtChanged = (e) => {
    if (['text', 'title'].includes(e.target.name) ) {
      let subMessages = [...this.state.subMessages]
      subMessages[e.target.dataset.id][e.target.name] = e.target.value
      this.setState({ subMessages })
    }
  }

  handleImageChange = (e, field, index) => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let subMessages = [...this.state.subMessages]
      subMessages[index][field] = file;
      subMessages[index][field + "Url"] = reader.result

      this.setState({ subMessages });
    };

    if (!file) return;
    reader.readAsDataURL(file);

    if (field === "image") {
      this.setState({ imgError: false });

      let img = new Image();
      let parent = this;
      img.onload = () => {
        let r = img.width / img.height;
        if (r < 1.9 || r > 1.92) {
          parent.setState({ imgRatio: r, imgError: true });
        }
      };

      let _URL = window.URL || window.webkitURL;
      img.src = _URL.createObjectURL(file);
    }
  }

  deleteSlide = (index) => {
    const { subMessages, form } = this.state;
    const newSubMessages = [...subMessages];
    const obj = newSubMessages[index];
    let next = null;
    if (index < newSubMessages.length - 1) next = newSubMessages[index + 1]._id
    if (index === 0) {
      form.next = next;
    } else {
      newSubMessages[index - 1].next = next;
    }
    newSubMessages.splice(index, 1);
    this.setState({ form, subMessages: newSubMessages })
  }

  render() {
    const { 
      form,
      subMessages,
      success,
      error,
      coords,
      logoUrl,
      imageUrl,
      loading,
      errorMessage,
    } = this.state;
    const { radius } = form;
    return (
      <div className="container-fluid position-relative">
        <div className="row justify-content-between mb-5">
            {loading && <LoadingScreen />}
            <ShowAlert success={success} error={error} errorMessage={errorMessage} />

            <AdForm
              form={form}
              onFormChange={form => {
                this.setState({ form });
              }}
              onCoordsChange={coords => {
                this.setState({ coords });
              }}
              onImgChange={urls => {
                this.setState(urls);
              }}
              subMessages={subMessages}
              addMessages={this.addMessages}
              txtChanged={this.txtChanged}
              handleImageChange={this.handleImageChange}
              deleteSlide={this.deleteSlide}
            />
          <div className="col-md-3 col-12">
              <div id="previewBlock">
                <h3>Preview of the Area</h3>
                {coords ? (
                  <MapPreview
                    coords={coords}
                    radius={radius}
                    isMarkerShown={coords}
                    googleMapURL={
                      "https://maps.googleapis.com/maps/api/js?key=" +
                      Conf.mapsKey
                    }
                    loadingElement={
                      <div style={{ height: `100%`, width: "100%" }} />
                    }
                    containerElement={
                      <div style={{ height: `120px`, width: "100%" }} />
                    }
                    mapElement={
                      <div style={{ height: `100%`, width: "100%" }} />
                    }
                  />
                ) : null}

                <h3>Preview of your ad</h3>
                <AdPreview
                  ad={form}
                  logoUrl={logoUrl}
                  imageUrl={imageUrl}
                  carouselSlides={subMessages}
                />
                <div className="form-group row">
                  <div className="col-12 text-center">
                    <button type="submit" className="btn btn-primary w-100" onClick={this.sendAll}>Save</button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    );
  }
}

const MapPreview = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap zoom={11} center={props.coords}>
      {props.isMarkerShown && <Marker position={props.coords} />}
      {props.isMarkerShown && props.radius && (
        <Circle
          options={{
            strokeColor: "#0000FF",
            strokeOpacity: 0.7,
            strokeWeight: 1,
            fillColor: "#0000FF",
            fillOpacity: 0.35
          }}
          center={props.coords}
          radius={props.radius ? parseInt(props.radius, 10) : 0}
        />
      )}
    </GoogleMap>
  ))
);
