import React, { Component } from "react";

import { Link, Redirect } from "react-router-dom";
import { Grid, Row, Col, Button } from "react-bootstrap";

import ReactTable from "react-table";
import "react-table/react-table.css";
import checkboxHOC from "react-table/lib/hoc/selectTable";
const CheckboxTable = checkboxHOC(ReactTable);

export default class List extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selection: [],
      selectAll: false,
      redirect: null
    };
  }

  componentDidMount() {
    this._getList();
  }

  render() {
    const checkboxProps = {
      selectAll: this.state.selectAll,
      isSelected: this.isSelected.bind(this),
      toggleSelection: this.toggleSelection.bind(this),
      toggleAll: this.toggleAll.bind(this),
      selectType: "checkbox"
    };

    if (this.state.redirect) return <Redirect to={this.state.redirect} />;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <button
              className={`btn ${this.state.selection.length ? "btn-primary" : "btn-secondary"}`}
              disabled={!this.state.selection.length}
              onClick={this.showStats.bind(this)}
            >
              Show stats
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <CheckboxTable
              ref={r => (this.checkboxTable = r)}
              {...checkboxProps}
              filterable
              className="-striped -highlight"
              noDataText='You have no ads, create one!'
              data={this.state.list}
              defaultPageSize={10}
              columns={[
                {
                  Header: "Title",
                  accessor: "title",
                  Cell: row =>
                    row.original.raw ? (
                      <Link to={"/edit-raw/" + row.original._id}>
                        {row.value}
                      </Link>
                    ) : (
                      <Link to={"/edit/" + row.original._id}>{row.value}</Link>
                    )
                },
                { Header: "Message", accessor: "text" },
                { Header: "Address", accessor: "address" },
                { Header: "Language", accessor: "lang" },
                {
                  Header: "Status",
                  accessor: "state",
                  Cell: row => (
                    <span>
                      <span
                        style={{
                          color:
                            row.value === "draft"
                              ? "gray"
                              : row.value === "online"
                              ? "#57d500"
                              : row.value === "review"
                              ? "#ffbf00"
                              : "#ff2e00",
                          transition: "all 1s ease"
                        }}
                      >
                        &#x25cf;
                      </span>
                      {" " + row.value}
                    </span>
                  )
                }
              ]}
            />
          </div>
        </div>
      </div>
    );
  }

  showStats() {
    let text = "";
    this.state.selection.map(e => {
      return (text += e + ",");
    });
    text = text.substr(0, text.length - 1);
    this.setState({ redirect: "/stats/" + text });
  }

  toggleSelection(key, shift, row) {
    // start off with the existing state
    let selection = [...this.state.selection];
    const keyIndex = selection.indexOf(row._id);
    // check to see if the key exists
    if (keyIndex >= 0) {
      // it does exist so we will remove it using destructing
      selection = [
        ...selection.slice(0, keyIndex),
        ...selection.slice(keyIndex + 1)
      ];
    } else {
      // it does not exist so add it
      selection.push(row._id);
    }
    // update the state
    this.setState({ selection });
  }

  toggleAll() {
    const selectAll = this.state.selectAll ? false : true;
    const selection = [];
    if (selectAll) {
      // we need to get at the internals of ReactTable
      const wrappedInstance = this.checkboxTable.getWrappedInstance();
      // the 'sortedData' property contains the currently accessible records based on the filter and sort
      const currentRecords = wrappedInstance.getResolvedState().sortedData;
      // we just push all the IDs onto the selection array
      currentRecords.forEach(item => {
        selection.push(item._original._id);
      });
    }
    this.setState({ selectAll, selection });
  }

  isSelected(key) {
    return this.state.selection.includes(key);
  }

  getJWTToken() {
    return localStorage.getItem("token");
  }

  _getList = async () => {
    this.setState({ error: false });
    const server = await localStorage.getItem('env');
    fetch(server + "ads/", {
      method: "GET",
      headers: {
        Authorization: "JWT " + this.getJWTToken()
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json && json.length) {
          return this.setState({ list: json });
        }
      })
      .catch(err => {
        this.setState({ error: true, errorMessage: err.error });
      });
  }
}
