import React, { Component } from "react";

import { Redirect } from "react-router-dom";

import {
  Form,
  FormGroup,
  FormControl,
  ControlLabel,
  Button,
  Alert,
  Grid,
  Col,
  Row
} from "react-bootstrap";

export default class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mail: "",
      pass: "",
      error: false,
      redirect: false
    };
  }

  txtChanged = (event) => {
    let name = event.target.name;
    this.setState({ [name]: event.target.value });
  }

  connect = async (event) => {
    event.preventDefault();
    const server = await localStorage.getItem('env');
    this.setState({ error: false });
    fetch(server + "ads/login", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ mail: this.state.mail, pass: this.state.pass })
    })
      .then(response => response.json())
      .then(async json => {
        if (json && json.jwt_token) {
          await localStorage.setItem("token", json.jwt_token);
          window.location = window.location.origin;
          return this.setState({ redirect: true });
        }

        throw new Error("Login failed");
      })
      .catch(err => {
        this.setState({ error: true });
        console.log(err);
      });
  }

  _renderError = () => {
    if (this.state.error) {
      return (
        <div className="alert alert-danger" role="alert">
          An error happened. Please check the email and password you entered.
        </div>
      );
    }

    return null;
  }

  render() {
    if (this.state.redirect) {
      return <Redirect to="/list" />;
    }

    return (
      <div className="container-fluid">
        <div className="row justify-content-center">
          <div className="col-6">
            <form>
              {this._renderError()}
              <div className="form-group row">
                <label htmlFor="formHorizontalEmail" className="col-md-2 col-form-label">Email</label>
                <div className="col-md-10">
                  <input
                    className="form-control"
                    id="formHorizontalEmail"
                    onChange={this.txtChanged}
                    value={this.state.mail}
                    name="mail"
                    type="email"
                    placeholder="Email"
                  />
                </div>
              </div>
              <div className="form-group row">
                <label htmlFor="formHorizontalPassword" className="col-md-2 col-form-label">Password</label>
                <div className="col-md-10">
                  <input
                    type="password"
                    className="form-control"
                    id="formHorizontalPassword"
                    placeholder="Password"
                    name="pass"
                    value={this.state.pass}
                    onChange={this.txtChanged}
                  />
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12 text-center">
                  <button type="submit" className="btn btn-primary" onClick={this.connect}>Sign in</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}
