import React, { Component } from "react";

import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import { Navbar, Nav, NavItem, Form, FormControl } from "react-bootstrap";

export class NavWrapper extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { envs, env, handleChange } = this.props;
    return (
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
          <Link className="navbar-brand" to="/">Seety business</Link>
          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
          {this._renderMenu()}
          <form className="form-inline my-2 my-lg-0" style={{ float: 'left', padding: '8px' }}>
            <select
              name="selectEnv"
              onChange={handleChange}
              className="form-control mr-sm-2"
              value={env}>
              {Object.keys(envs).map(env => (
                <option key={env} value={env}>{env}</option>
              ))}
            </select>
          </form>
          <span className="navbar-text">
            {this.state.business && this.state.business.businessName}
          </span>
        </div>
      </nav>
    );
  }

  _renderMenu() {
    if (!this.isAuthenticated) {
      return (
        <ul className="navbar-nav mr-auto">
          <li className="nav-item">
            <Link className="nav-link" to="/login">Login</Link>
          </li>
        </ul>
      );
    }

    return (
      <ul className="navbar-nav mr-auto">
        <li className="nav-item">
          <Link className="nav-link" to="/create">Create Ad</Link>
        </li>
        <li className="nav-item">
          <Link className="nav-link" to="/list">List Ads</Link>
        </li>
        {/*<li className="nav-item">
          <Link className="nav-link" to="/create-raw">Create Raw Ad</Link>
    </li>*/}
        <li className="nav-item">
          <Link className="nav-link" to="/topup">Topup account</Link>
        </li>
      </ul>
    );
  }

  logOut() {
    localStorage.clear();
  }

  _updateUser = async () => {
    const server = await localStorage.getItem('env');
    fetch(server + "business", {
      method: "GET",
      headers: {
        Authorization: "JWT " + this.getJWTToken()
      }
    })
      .then(response => response.json())
      .then(business => {
        return this.setState({ business });
      })
      .catch(err => {
        console.log(err);
      });
  }

  getJWTToken() {
    return localStorage.getItem("token");
  }

  get isAuthenticated() {
    let token = this.getJWTToken();
    if (token && !this.state.business) this._updateUser();
    return token;
  }
}
