import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  FormGroup,
  FormControl,
  ControlLabel,
  HelpBlock,
  Alert,
} from "react-bootstrap";
import AliceCarousel from 'react-alice-carousel';

import "react-alice-carousel/lib/alice-carousel.css";
import LOGO from "../../logo.png";

import Conf from "../../config.js";
import "./create.css";

/**
 * Display the creation/edition form of an ad.
 **/
export class AdForm extends Component {
  static propTypes = {
    /**
     * Array definition of the form to render (the values and the names of the fields)
     **/
    form: PropTypes.object.isRequired,
    /**
     * Function called after image change (useful for rendering the new one)
     **/
    onImgChange: PropTypes.func,

    /**
     * Function called after form change (useful for rendering the new preview)
     **/
    onFormChange: PropTypes.func.isRequired
  };

  static propDefaults = {
    onImgChange: () => {}
  };

  constructor(props) {
    super(props);

    this.state = {
      form: {
        title: "",
        text: "",
        conditions: "",
        address: "",
        radius: "",
        link: "",
        onlyOnce: false,
        forNewUsers: false,
        priority: '3',
        state: 'draft',
        hideButton: false,
        ...props.form
      },
      error: false,
      success: false,
      chk: {},
      coords: null,
      imgError: null
    };
  }

  componentWillReceiveProps(next) {
    this.setState({ form: next.form });
  }

  _txtChanged = event => {
    let name = event.target.name;
    let value = event.target.value;
    let form = this.state.form;
    form[name] = value;
    this.setState({ form });
    this.props.onFormChange(form);
  };

  _chkChanged = event => {
    const form = this.state.form;
    const name = event.target.name;
    if (name !== 'days') {
      form[name] = event.target.checked;
    } else {
      const array = this.state.form[name] || [];
      const value = parseInt(event.target.value, 10);
      const checked = event.target.checked;
      if (checked) array.push(value);
      else {
        const index = array.indexOf(value);
        array.splice(index, 1);
      }

      form[name] = array;
    }
    this.setState({ form });
    this.props.onFormChange(form);
  };

  _enabledChanged(event) {
    let form = this.state.form;
    form.enabled = event.target.checked;
    this.setState({ form });
    this.props.onFormChange(form);
  }

  _handleImageChange(e, field) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let form = this.state.form;
      form[field] = file;

      this.setState({
        form,
        [field]: file,
        [field + "Url"]: reader.result
      });
      this.props.onFormChange(form);
      this.props.onImgChange({ [field + "Url"]: reader.result });
    };

    if (!file) return;
    reader.readAsDataURL(file);

    if (field === "image") {
      this.setState({ imgError: false });

      let img = new Image();
      let parent = this;
      img.onload = () => {
        let r = img.width / img.height;
        if (r < 1.9 || r > 1.92) {
          parent.setState({ imgRatio: r, imgError: true });
        }
      };

      let _URL = window.URL || window.webkitURL;
      img.src = _URL.createObjectURL(file);
    }
  }

  _geocode(addr, cbk) {
    if (addr && addr.length > 3) {
      return fetch(
        "https://maps.googleapis.com/maps/api/geocode/json?address=" +
          addr +
          "&key=" +
          Conf.mapsKey
      )
        .then(d => d.json())
        .then(json => {
          if (json.results && json.results.length) {
            let coords = json.results[0].geometry.location;
            let form = this.state.form;
            form.coords = JSON.stringify(coords);
            this.setState({ form });
            this.props.onFormChange(form);
            this.props.onCoordsChange(coords);
          }
        })
        .catch(err => {
          console.warn(err);
        });
    }

    return null;
  }

  _renderMoreMessages = () => {
    const { subMessages, txtChanged, handleImageChange, deleteSlide } = this.props;
    if (subMessages) {
      const html = subMessages.map((obj, i) => (
        <div key={i} className="mt-5">
          <div className="d-flex align-items-center justify-content-between">
            <h4>Message #{i+1}</h4>
            <button className="btn btn-outline-danger btn-sm" onClick={() => deleteSlide(i)}>Delete</button>
          </div>
          <FieldControl
            name="title"
            value={obj.title}
            id={`title-${i}`}
            type="textarea"
            onChange={txtChanged}
            data-id={i}
            label="Text Above Image"
          />
          <FieldControl
            name="text"
            value={obj.text}
            id={`text-${i}`}
            type="textarea"
            onChange={txtChanged}
            data-id={i}
            label="Text Under Image"
          />
          <FieldControl
            data-id={i}
            type="file"
            id={`file-${i}`}
            name="image"
            label="Picture of your ad (jpg or png)"
            accept="image/png, image/jpeg"
            help="The ratio of the image has to be 1x1 if you don't use text, or 1x1.91 if you use text"
            onChange={e => handleImageChange(e, "image", i)}
          />
        </div>
      ))
      return html;
    }
    return null;
  }

  render() {
    const { addMessages } = this.props;
    const { form: {
      title,
      text,
      conditions,
      address,
      displayAddress,
      place,
      radius,
      hours,
      link,
      ctaText,
      priority,
      lang,
      state,
      hideButton,
      onlyOnce,
      forNewUsers,
      alone,
      days,
    }} = this.state;
    return (
      <>
        <div className="col-md-3 col-5">
          <FieldControl
            id="formTile"
            label="Title"
            type="text"
            name="title"
            value={title}
            onChange={this._txtChanged}
          />

          <FieldControl
            id="formControlsText"
            label="Text Under Image"
            type="textarea"
            name="text"
            value={text}
            onChange={this._txtChanged}
          />

          <FieldControl
            id="formControlsConditions"
            label="Conditions"
            type="textarea"
            name="conditions"
            value={conditions}
            onChange={this._txtChanged}
          />

          <FieldControl
            id="formControlsAddress"
            label="Address"
            type="text"
            name="address"
            value={address}
            onChange={this._txtChanged}
            onBlur={ev => this._geocode(ev.target.value)}
          />

          <FieldControl
            id="displayAddress"
            label="Display address (left of CTA button)"
            type="text"
            name="displayAddress"
            value={displayAddress}
            onChange={this._txtChanged}
          />

          <FieldControl
            id="place"
            label="Place name (to display instead of distance)"
            type="text"
            name="place"
            value={place}
            onChange={this._txtChanged}
          />

          <FieldControl
            id="formRadius"
            label="Radius (in meters)"
            type="number"
            name="radius"
            value={radius}
            onChange={ev => {
              this._txtChanged(ev);
              this.setState({ radius: parseInt(ev.target.value, 10) });
            }}
          />

          <div className="form-group">
            <label className="mr-3 control-label">Days</label>
            <CheckBoxControl inline name="days" value="1" onChange={this._chkChanged} checked={days && days.includes(1)} label="Mon" />
            <CheckBoxControl inline name="days" value="2" onChange={this._chkChanged} checked={days && days.includes(2)} label="Tue" />
            <CheckBoxControl inline name="days" value="3" onChange={this._chkChanged} checked={days && days.includes(3)} label="Wed" />
            <CheckBoxControl inline name="days" value="4" onChange={this._chkChanged} checked={days && days.includes(4)} label="Thu" />
            <CheckBoxControl inline name="days" value="5" onChange={this._chkChanged} checked={days && days.includes(5)} label="Fri" />
            <CheckBoxControl inline name="days" value="6" onChange={this._chkChanged} checked={days && days.includes(6)} label="Sat" />
            <CheckBoxControl inline name="days" value="0" onChange={this._chkChanged} checked={days && days.includes(0)} label="Sun" />
          </div>

          <FieldControl
            id="formHours"
            type="text"
            label="Hours to display ad (GMT)"
            help="1-3 means during hour 1, 2 and 3. can also be separated by commas"
            name="hours"
            value={this.state.form.hours}
            onChange={this._txtChanged}
          />

          <div className="form-group">
            <CheckBoxControl name="onlyOnce" checked={onlyOnce} value="1" inline onChange={this._chkChanged} label="Only visible once" />
          </div>

          <div className="form-group">
            <CheckBoxControl name="alone" checked={alone} value="1" inline onChange={this._chkChanged} label="Only visible alone" />
          </div>

          <div className="form-group">
            <CheckBoxControl name="forNewUsers" checked={forNewUsers} value="1" inline onChange={this._chkChanged} label="For new users" />
          </div>

        </div>
        <div className="col-md-3 col-5">
          <div className="form-group">
            <label htmlFor="formControlPriority">Priority</label>
            <select
              className="custom-select my-1 mr-sm-2"
              id="formControlPriority"
              name="priority"
              value={priority}
              onChange={this._txtChanged}
            >
              <option value="5">High</option>
              <option value="3">Medium</option>
              <option value="1">Low</option>
            </select>
          </div>

          <FieldControl
            id="formLogo"
            name="logo"
            type="file"
            label="Logo of your brand (jpg or png)"
            accept="image/png, image/jpeg"
            help="The ratio of the logo has to be 1x1 and the size must be at least 256 pixels"
            onChange={e => this._handleImageChange(e, "logo")}
          />

          <FieldControl
            id="formAd"
            type="file"
            name="image"
            label="Picture of your ad (jpg or png)"
            accept="image/png, image/jpeg"
            help="The ratio of the image has to be 1x1 if you don't use text, or 1x1.91 if you use text"
            onChange={e => this._handleImageChange(e, "image")}
          />

          {this._renderImgError()}
          
          <div className="form-group">
            <CheckBoxControl name="hideButton" checked={hideButton} value="1" inline onChange={this._chkChanged} label="Hide Action button" />
          </div>

          <FieldControl
            id="formLink"
            label="Call to action (link, leave blank if you want navigation or carousel to show)"
            type="url"
            name="link"
            value={link}
            onChange={this._txtChanged}
          />

          <FieldControl
            id="ctaText"
            label="Call to action text (button)"
            type="text"
            name="ctaText"
            value={ctaText}
            onChange={this._txtChanged}
          />

          <div className="form-group">
            <label htmlFor="formControlLang">Language (FR, NL, EN ?)</label>
            <select
              className="custom-select my-1 mr-sm-2"
              id="formControlLang"
              name="lang"
              value={lang}
              onChange={this._txtChanged}
            >
              <option value="fr">French</option>
              <option value="en">English</option>
              <option value="nl">Dutch</option>
            </select>
          </div>

          <div className="form-group">
            <label htmlFor="formControlState">State of the Ad (Online means visible by users)</label>
            <select
              className="custom-select my-1 mr-sm-2"
              id="formControlState"
              name="state"
              value={state}
              onChange={this._txtChanged}
            >
              <option value="draft">Draft</option>
              <option value="review">In review</option>
              <option value="online">Online</option>
              <option value="disabled">Disabled</option>
              <option value="removed">Removed</option>
            </select>
          </div>

          {/*<FormGroup controlId="formControlLang">
            <ControlLabel>
              State of the Ad (Online means visible by users)
            </ControlLabel>
            <FormControl
              componentclassName="select"
              name="state"
              onChange={this._txtChanged}
            >
              <option value="draft">Draft</option>
              <option value="review">In review</option>
              <option value="online">Online</option>
              <option value="disabled">Disabled</option>
              <option value="removed">Removed</option>
            </FormControl>
          </FormGroup>
      */}

          {this._renderMoreMessages()}

          <div className="form-group row">
            <div className="col-12 text-center">
              <button type="submit" className="btn btn-primary" id="addMessageBtn" onClick={addMessages}>Add Message to carousel</button>
            </div>
          </div>
        </div>
      </>
    );
  }

  _renderImgError() {
    if (this.state.imgError)
      return (
        <Alert bsStyle="danger">
          The ratio of the dimensions of the image has to be close of 1.91 (like
          1200 x 628, like Facebook) if you include text or must be squared if
          your ad is only composed of an image.
        </Alert>
      );

    return null;
  }
}

const buttonGo = {
  fr: 'En profiter !',
  nl: 'Krijg het!',
  en: 'Get it!',
}

const buttonGoDist = {
  fr: 'Y aller !',
  nl: 'Gaan!',
  en: 'Go!',
}

export class AdPreview extends Component {
  render() {
    const { ad, logoUrl, imageUrl, carouselSlides } = this.props;
    const items = carouselSlides.map(msg => <Slide message={msg} />)
    let buttonText = buttonGoDist[ad.lang];
    if (ad.ctaText) buttonText = ad.ctaText;
    else if (ad.link) buttonText = buttonGo[ad.lang];
    return (
      <>
        <div id="titlePrev" className="d-flex">
          <img
            id="logoPreview"
            alt="Logo preview"
            src={logoUrl ? logoUrl : LOGO}
          />
          <div className="container-fluid d-flex justify-content-between align-items-center">
            <div className="prevTitle">{ad.title || <Placeholder text="Title" />}</div>
            <span className="prevPlace">
              {ad.place || <Placeholder text="...m" />}
            </span>
          </div>
        </div>

        <div id="contentPreview">
          <h5>{ad.title || <Placeholder text="Title" />}</h5>
          <img
            id="adPicPreview"
            alt="Ads preview"
            src={imageUrl ? imageUrl : LOGO}
          />
          <div id="textPreview">{ad.text || <Placeholder text="Text" />}</div>
          <div className="contentBottom">
            <div id="address">
              {ad.displayAddress || ad.address || <Placeholder text="Address" />}
            </div>
            {!ad.hideButton && <div id="buttonCTA">
              {buttonText}
            </div>}
          </div>

          <div id="conditions">{ad.conditions || <Placeholder text="Conditions" />}</div>
          {!!carouselSlides.length && <div style={{ textAlign: 'center', marginTop: 20 }}>
            <h4>Carousel</h4>
            <div className="carousel">
              <AliceCarousel
                mouseDragEnabled
                items={items}
                dotsDisabled
                infinite={false}
                ref={(el) => (this.Carousel = el)}
              >
              </AliceCarousel>
            </div>
          </div>}
        </div>
      </>
    );
  }
}

export const Placeholder = ({text}) => (
<span className="placeholderComponent">{text}</span>
)

export const Slide = ({ message }) => {
  const { title, image, imageUrl, text } = message;
  const src = imageUrl || image;
  const handleOnDragStart = (e) => e.preventDefault();
  return (
    <div className="slide">
      <h5>{title || <Placeholder text="Title" />}</h5>
      <img onDragStart={handleOnDragStart} src={src} style={{ maxWidth: 150 }}/>
      <h5>{text || <Placeholder text="Title" />}</h5>
    </div>
  )
}

export class AdFormRaw extends Component {
  static propTypes = {
    /**
     * Array definition of the form to render (the values and the names of the fields)
     **/
    form: PropTypes.object.isRequired,
    /**
     * Function called after form change (useful for rendering the new preview)
     **/
    onFormChange: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      form: {
        title: "",
        text: "",
        conditions: "",
        address: "",
        link: "",
        ctaText: "",
        place: "",
        image: "",
        displayAddress: "",
        ...props.form
      },
      error: false,
      success: false,
      chk: {},
      coords: null,
      imgError: null
    };
  }

  componentWillReceiveProps(next) {
    this.setState({ form: next.form });
    //	this.props = next;
  }

  _txtChanged = event => {
    let name = event.target.name;
    let value = event.target.value;
    let form = this.state.form;
    form[name] = value;

    this.setState({ form });
    this.props.onFormChange(form);
  };

  _handleImageChange(e, field) {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];

    reader.onloadend = () => {
      let form = this.state.form;
      form[field] = file;

      this.setState({
        form,
        [field]: file,
        [field + "Url"]: reader.result
      });
      this.props.onFormChange(form);
    };

    if (!file) return;
    reader.readAsDataURL(file);

    if (field === "image") {
      this.setState({ imgError: false });

      let img = new Image();
      let parent = this;
      img.onload = () => {
        let r = img.width / img.height;
        if (r < 1.9 || r > 1.92) {
          parent.setState({ imgRatio: r, imgError: true });
        }
      };

      let _URL = window.URL || window.webkitURL;
      img.src = _URL.createObjectURL(file);
    }
  }

  render() {
    return (
      <div>
        <FieldGroup
          id="formTitle"
          label="Title"
          name="title"
          type="text"
          value={this.state.form.title}
          onChange={this._txtChanged}
        />

        <FormGroup controlId="formControlsText">
          <ControlLabel>Text</ControlLabel>
          <FormControl
            name="text"
            value={this.state.form.text}
            componentclassName="textarea"
            onChange={this._txtChanged}
          />
        </FormGroup>

        <FormGroup controlId="formControlsConditions">
          <ControlLabel>Conditions</ControlLabel>
          <FormControl
            name="conditions"
            value={this.state.form.conditions}
            componentclassName="textarea"
            onChange={this._txtChanged}
          />
        </FormGroup>

        <FieldGroup
          id="formControlsAddress"
          label="Address"
          type="text"
          name="address"
          value={this.state.form.address}
          onChange={this._txtChanged}
        />

        <FieldGroup
          id="displayAddress"
          label="Display address (left of CTA button)"
          type="text"
          name="displayAddress"
          value={this.state.form.displayAddress}
          onChange={this._txtChanged}
        />

        <FieldGroup
          id="place"
          label="Place name (to display instead of distance)"
          type="text"
          name="place"
          value={this.state.form.place}
          onChange={this._txtChanged}
        />

        <FieldGroup
          id="formAd"
          type="file"
          name="image"
          label="Picture of your ad (jpg or png)"
          accept="image/png, image/jpeg"
          help="The ratio of the image has to be 1x1 if you don't use text, or 1x1.91 if you use text"
          onChange={e => this._handleImageChange(e, "image")}
        />
        {this._renderImgError()}

        <FieldGroup
          id="formLink"
          label="Call to action (link, leave blank if you want navigation)"
          type="url"
          name="link"
          value={this.state.form.link}
          onChange={this._txtChanged}
        />

        <FieldGroup
          id="ctaText"
          label="Call to action text (button)"
          type="text"
          name="ctaText"
          value={this.state.form.ctaText}
          onChange={this._txtChanged}
        />
      </div>
    );
  }

  _renderImgError() {
    if (this.state.imgError)
      return (
        <Alert bsStyle="danger">
          The ratio of the dimensions of the image has to be close of 1.91 (like
          1200 x 628, like Facebook) if you include text or must be squared if
          your ad is only composed of an image.
        </Alert>
      );

    return null;
  }
}

export const FieldControl = (props) => {
  const { id, label, type, help, ...rest } = props;

  return (
    <div className={'form-group'}>
      <div className={`${type === 'file' ? 'custom-file' : ''}`}>
        <label className={`${type === 'file' ? 'custom-file-label' : 'control-label'}`} htmlFor={id}>{label}</label>
        {type === 'textarea' ? <textarea type="text" className="form-control" id={id} {...rest} /> : <input className={`${type === 'file' ? 'custom-file-input' : 'form-control'}`} type={type} id={id} {...rest} />}
      </div>
      {help && (<small className="form-text text-muted mb-2">{help}</small>)}
    </div>
  );
}

FieldControl.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  help: PropTypes.string,
};

export const CheckBoxControl = (props) => {
  const {label, inline, ...rest} = props;

  const className = inline ? 'form-check-inline' : 'form-check';

  return (
    <div className={className}>
      <label className="form-check-label">
      <input className="form-check-input" type="checkbox" {...rest} />
        {label}
      </label>
    </div>
  )
}

CheckBoxControl.propTypes = {
  label: PropTypes.string,
  inline: PropTypes.bool,
}

CheckBoxControl.defaultProps = {
  label: '',
  inline: false,
}

export const ShowAlert = ({error, success, errorMessage}) => (
  <div className="col-12">
    {error ? (
      <div className="alert alert-danger" role="alert">
        An error happened: {errorMessage}
      </div>
    ) : null}

    {success ? (
      <div className="alert alert-success" role="alert">
        Success, the ad is now created and will be reviewed by our team.
        You'll receive an email when your ad is approved.
      </div>
    ) : null}
  </div>
);

export const LoadingScreen = () => (
  <div className="loadingScreen">
    <div className="spinner-border" style={{width: '3rem', height: '3rem'}} role="status">
      <span className="sr-only">Loading...</span>
    </div>
  </div>
)

export class FieldGroup extends Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    help: PropTypes.string
  };

  render() {
    return (
      <FormGroup controlId={this.props.id}>
        <ControlLabel>{this.props.label}</ControlLabel>
        <FormControl {...this.props} />
        {this.props.help && <HelpBlock>{this.props.help}</HelpBlock>}
      </FormGroup>
    );
  }
}
