import React, { Component } from "react";
import { Redirect } from "react-router-dom";

import { Link } from "react-router-dom";
import { Grid, Row, Col } from "react-bootstrap";

export default class Home extends Component {
  render() {
    if (localStorage.getItem('token')) return <Redirect to="/list" />;
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-xs-12">
            Please <Link to="/Login">login</Link> to get access to the platform.
          </div>
        </div>
      </div>
    );
  }
}
