import React, { Component } from "react";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

import Ads from "./views/Ads";
import Login from "./views/Login";
import Home from "./views/Home";
import Transactions from "./views/Transactions";

import { NavWrapper } from "./components";

import "./App.css";

function getKeyByValue(object, value) {
  return Object.keys(object).find(key => object[key] === value);
}

class App extends Component {

  state = {
    env: localStorage.getItem('env') || 'prod',
  }

  envs = {
    preprod: 'https://api.beta.cparkapp.com/',
    prod: 'https://api.cparkapp.com/',
    dev: 'http://localhost:4700/'
  }

  componentDidMount() {
    const { env } = this.state;
    const localEnv = localStorage.getItem('env');
    if (!localEnv) {
      localStorage.setItem('env', env);
    } else {
      this.setState({ env: getKeyByValue(this.envs, localEnv) })
    }
  }

  handleChange = async (e) => {
    const value = e.target.value;
    this.setState({ env: e.target.value});
    if (await localStorage.getItem('token')) await localStorage.removeItem('token');
    await localStorage.setItem('env', this.envs[value]);
    window.location = window.location.origin;
  }


  render() {
    const { env } = this.state;

    return (
      <Router>
        <Root>
          <NavWrapper envs={this.envs} env={env} handleChange={this.handleChange} />

          <Route path="/" exact={true} component={Home} />
          <Route path="/login" exact={true} component={Login} />

          <PrivateRoute path="/list" exact={true} component={Ads.List} />
          <PrivateRoute path="/create" exact={true} component={Ads.Create} />
          {/*<PrivateRoute
            path="/create-raw"
            exact={true}
            component={Ads.CreateRaw}
          />*/}
          <PrivateRoute path="/edit/:id" exact={true} component={Ads.Edit} />
          {/*<PrivateRoute
            path="/edit-raw/:id"
            exact={true}
            component={Ads.EditRaw}
          />*/}
          <PrivateRoute path="/stats/:ids" exact={true} component={Ads.Stats} />
          <PrivateRoute
            path="/topup"
            exact={true}
            component={Transactions.Topup}
          />
        </Root>
      </Router>
    );
  }
}

const Root = props => <div {...props} />;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      localStorage.getItem("token") ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
            state: { from: props.location }
          }}
        />
      )
    }
  />
);

export default App;
