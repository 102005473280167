import React, { Component } from "react";

import { Grid, Row, Col, Button, Alert } from "react-bootstrap";
import { AdFormRaw } from "./components.js";

import "./create.css";

export default class CreateRaw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: {},
      imageUrl: ""
    };
  }

  getJWTToken() {
    return localStorage.getItem("token");
  }

  send = async event => {
    let formData = new FormData();
    let data = this.state.form;
    for (let name in data) {
      formData.append(name, data[name]);
    }
    const server = await localStorage.getItem('env');
    this.setState({ error: false });
    fetch(server + "ads/raw", {
      method: "POST",
      headers: {
        Authorization: "JWT " + this.getJWTToken()
      },
      body: formData
    })
      .then(response => response.json())
      .then(json => {
        if (json && json._id) return this.setState({ success: true });

        throw json.error;
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: true, errorMessage: err.error, success: false });
      });

    event.preventDefault();
  };

  render() {
    return (
      <Grid fluid={true}>
        <Row className="flex-space-between">
            {this._renderAlerts()}

            <AdFormRaw
              form={this.state.form}
              onFormChange={form => {
                this.setState({ form });
              }}
            />
            <Button
              bsStyle="primary"
              id="sendButton"
              bsSize="large"
              type="submit"
              onClick={this.send}
            >
              Save
            </Button>
          <Col xs={4} md={3} />
        </Row>
      </Grid>
    );
  }

  _renderAlerts() {
    return (
      <>
        {this.state.error ? (
          <Alert bsStyle="danger">
            An error happened: {this.state.errorMessage}
          </Alert>
        ) : null}

        {this.state.success ? (
          <Alert bsStyle="success">
            Success, the ad is now created and will be reviewed by our team.
            You\'ll receive an email when your ad is approved.
          </Alert>
        ) : null}
      </>
    );
  }
}
