import React, { Component } from "react";

import { FormGroup, Grid, Row, Col, Button, Alert } from "react-bootstrap";
import { AdFormRaw } from "./components.js";

import "./create.css";

export default class EditRaw extends Component {
  constructor(props) {
    super(props);
    this.state = {
      form: { lang: "fr", enabled: true },
      imageUrl: "",
      id: props.match.params.id
    };
  }

  getJWTToken() {
    return localStorage.getItem("token");
  }

  componentWillMount() {
    this.get(this.state.id);
  }

  get = async (id) => {
    const server = await localStorage.getItem('env');
    fetch(server + "ads/" + id, {
      method: "GET",
      headers: {
        Authorization: "JWT " + this.getJWTToken()
      }
    })
      .then(response => response.json())
      .then(json => {
        if (json && json._id) {
          return this.setState({
            form: json,
            imageUrl: json.image.replace("public/", server)
          });
        }

        this.setState({
          error: true,
          errorMessage: json.message,
          success: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: true, errorMessage: err.error, success: false });
      });
  }

  send = async event => {
    let formData = new FormData();
    let data = this.state.form;
    for (let name in data) {
      formData.append(name, data[name]);
    }
    const server = await localStorage.getItem('env');
    this.setState({ error: false });
    fetch(server + "ads/raw/" + this.state.id, {
      method: "PUT",
      headers: {
        Authorization: "JWT " + this.getJWTToken()
      },
      body: formData
    })
      .then(response => response.json())
      .then(json => {
        if (json && json._id) return this.setState({ success: true });

        this.setState({
          error: true,
          errorMessage: json.message,
          success: false
        });
      })
      .catch(err => {
        console.log(err);
        this.setState({ error: true, errorMessage: err.error, success: false });
      });

    event.preventDefault();
  };

  render() {
    return (
      <Grid fluid={true}>
        <Row>
          <Col xs={8}>
            {this._renderAlerts()}
            <AdFormRaw
              form={this.state.form}
              onFormChange={form => {
                this.setState({ form });
              }}
            />

            <FormGroup>
              <Button bsStyle="primary" type="submit" onClick={this.send}>
                Send
              </Button>
            </FormGroup>
          </Col>
          <Col xs={4} />
        </Row>
      </Grid>
    );
  }

  _renderAlerts() {
    return (
      <div>
        {this.state.error ? (
          <Alert bsStyle="danger">
            An error happened: {this.state.errorMessage}
          </Alert>
        ) : null}

        {this.state.success ? (
          <Alert bsStyle="success">
            Success, the ad is now created and will be reviewed by our team.
            You\'ll receive an email when your ad is approved.
          </Alert>
        ) : null}
      </div>
    );
  }
}
