import React, { Component } from "react";
import moment from "moment";
//import {Link} from 'react-router-dom';
import {
  Grid,
  Row,
  Col,
  Form,
  FormGroup,
  ControlLabel,
  FormControl
} from "react-bootstrap";
import Conf from "../../config.js";
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker
} from "react-google-maps";
import { MarkerClusterer } from "react-google-maps/lib/components/addons/MarkerClusterer";

import { Bar as BarChart, Doughnut, Line as LineChart } from "react-chartjs-2";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import "./stats.css";

export default class Stats extends Component {
  constructor(props) {
    super(props);

    this.colorsA = {
      red: "rgba(255, 99, 132, 0.3)",
      orange: "rgba(255, 159, 64, 0.3)",
      yellow: "rgba(255, 205, 86, 0.3)",
      green: "rgba(75, 192, 192, 0.3)",
      blue: "rgba(54, 162, 235, 0.3)",
      purple: "rgba(153, 102, 255, 0.3)",
      grey: "rgba(201, 203, 207, 0.3)"
    };

    this.colors = {
      red: "rgb(255, 99, 132)",
      orange: "rgb(255, 159, 64)",
      yellow: "rgb(255, 205, 86)",
      green: "rgb(75, 192, 192)",
      blue: "rgb(54, 162, 235)",
      purple: "rgb(153, 102, 255)",
      grey: "rgb(201, 203, 207)"
    };

    this.state = {
      uniqueGraph: this.formatDataTotal(),
      hoursGraph: this.formatDataHours(),
      timeGraph: { data: {}, options: {} },
      osGraph: this.formatDataOS(),
      mapPoints: [],

      begin: moment().subtract(1, "month"),
      end: moment(),
      granularity: "day"
    };
  }

  componentDidMount() {
    this.updateStats();
  }

  updateStats() {
    this._getStats(this.props.match.params.ids);
  }

  formatDataTotal(data) {
    return {
      data: {
        labels: [
          `Sent Unique (${data ? data.displays || 0 : 0})`,
          "Read (" +
            (data ? data.opens || 0 : 0) +
            ")",
          "Clicks (" +
            (data ? data.clicks || 0 : 0) +
            ")"
        ],
        datasets: [
          {
            data: data ? [data.displays, data.opens, data.clicks] : [0, 0, 0],
            backgroundColor: [
              this.colorsA.red,
              this.colorsA.blue,
              this.colorsA.green
            ],
            borderColor: [this.colors.red, this.colors.blue, this.colors.green],
            borderWidth: 1
          }
        ]
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true
              }
            }
          ]
        },
        title: {
          display: false,
          text: "Total metrics for the ads"
        },
        legend: {
          display: false
        }
      }
    };
  }

  formatDataOS(data) {
    return {
      data: {
        labels: ["Android", "iPhone"],
        datasets: [
          {
            label: "Total",
            data: data ? [data.fb_android, data.fb_ios] : [0, 0],
            backgroundColor: [this.colorsA.orange, this.colorsA.purple],
            borderColor: [this.colors.orange, this.colors.purple],
            borderWidth: 1
          }
        ]
      },
      options: {
        title: {
          display: false,
          text: "OS repartition for opens of the ads"
        }
      }
    };
  }

  formatDataTime(data) {
    const { begin, end, granularity } = this.state;
    let labels = [];
    let values = { sent: [], open: [], click: [] };

    let d = moment(begin);


    while (end.isAfter(d)) {
      const formatedDate = d.format(this._getFormat(granularity));
      labels.push(formatedDate);

      let index = d.format(this._getFormatBackend(granularity));

      values.open.push(data.open[index] ? data.open[index] : 0);
      values.click.push(data.click[index] ? data.click[index] : 0);
      values.sent.push(data.sent[index] ? data.sent[index] : 0);

      d = d.add(1, this.state.granularity);
    }

    return {
      data: {
        labels,
        datasets: [
          {
            label: "Sent Unique",
            data: values.sent,
            borderColor: this.colors.red,
            backgroundColor: this.colorsA.red,
            fill: false
          },
          {
            label: "Read",
            data: values.open,
            borderColor: this.colors.blue,
            backgroundColor: this.colorsA.blue,
            fill: false
          },
          {
            label: "Clicks on the link",
            data: values.click,
            borderColor: this.colors.green,
            backgroundColor: this.colorsA.green,
            fill: false
          }
        ]
      },
      options: {
        legend: {
          position: "top"
        },
        scales: {
          xAxes: [
            {
              type: "time",
              time: {
                //			    format: this._getFormat(this.state.granularity),
                // tooltipFormat: this._getFormat(this.state.granularity)
                unit: granularity,
              },
              scaleLabel: {
                display: false,
                labelString: "Date"
              }
            }
          ]
        },
        title: {
          display: false,
          text: "Summary of the ads for the period"
        }
      }
    };
  }

  formatDataHours(data) {
    let labels = [];
    let values = [];

    let i = 0;
    for (i = 0; i < 24; i++) {
      let s = i;

      labels.push(s + "h");

      s = (s - moment().utcOffset() / 60) % 24;
      if (s < 0) s += 24;

      let index = s < 10 ? "0" + s : s;
      values.push(data && data[index] ? data[index] : 0);
    }

    return {
      data: {
        labels,
        datasets: [
          {
            label: "Read",
            data: values,
            borderColor: this.colors.blue,
            backgroundColor: this.colorsA.blue,
            borderWidth: 1
          }
        ]
      },
      options: {
        legend: {
          display: false
        },
        title: {
          display: false,
          text: "Read of the ads during the day"
        }
      }
    };
  }

  handleChangeGranularity = async (event) => {
    const granularity = event.target.value;
    await this.setState({ granularity });
    this.updateStats();
  }

  async handleChange(field, date) {
    await this.setState({ [field]: moment(date) });
    this.updateStats();
  }

  render() {
    const { begin, end } = this.state;
    const startTime = new Date(begin.format("MM/DD/YYYY"))
    const endTime = new Date(end.format("MM/DD/YYYY"))
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <form className="form-inline inlineForm">
              <div className="form-group">
                <label>Period</label>
                <DatePicker
                  selected={startTime}
                  className="datePicker"
                  onChange={date => {
                    return this.handleChange("begin", date);
                  }}
                />
                <span> - </span>
                <DatePicker
                  selected={endTime}
                  className="datePicker"
                  onChange={date => {
                    return this.handleChange("end", date);
                  }}
                />{" "}
              </div>
              <div className="form-group">
                <label htmlFor="granularity">Granularity</label>
                <select
                  className="custom-select my-1 mr-sm-2"
                  id="granularity"
                  value={this.state.granularity}
                  onChange={this.handleChangeGranularity}
                >
                  <option value="hour">Hour</option>
                  <option value="day">Day</option>
                  <option value="week">Week</option>
                  <option value="month">Month</option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <h3>Total metrics for the campaign</h3>
            <BarChart
              data={this.state.uniqueGraph.data}
              options={this.state.uniqueGraph.options}
            />
          </div>

          <div className="col-6">
            <h3>OS repartition of users that read the messages</h3>
            <Doughnut
              data={this.state.osGraph.data}
              options={this.state.osGraph.options}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3>Metrics distribution of the campaign</h3>
            <LineChart
              data={this.state.timeGraph.data}
              options={this.state.timeGraph.options}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <h3>Split by hours of messages read</h3>
            <BarChart
              data={this.state.hoursGraph.data}
              options={this.state.hoursGraph.options}
            />
          </div>
        </div>

        <div className="row mb-5">
          <div className="col-12">
            <h3>Locations of messages read</h3>
            <MapPreview
              points={this.state.mapPoints}
              googleMapURL={
                "https://maps.googleapis.com/maps/api/js?key=" + Conf.mapsKey
              }
              loadingElement={<div style={{ height: `100%`, width: "100%" }} />}
              containerElement={
                <div style={{ height: `300px`, width: "100%" }} />
              }
              mapElement={<div style={{ height: `100%`, width: "100%" }} />}
            />
          </div>
        </div>
      </div>
    );
  }

  getJWTToken() {
    return localStorage.getItem("token");
  }

  _getStats = async (ids) => {
    const server = await localStorage.getItem('env');
    this.setState({ error: false });
    fetch(
      server +
        "ads/stats/" +
        ids +
        "?begin=" +
        this.state.begin.toISOString() +
        "&end=" +
        this.state.end.toISOString() +
        "&granularity=" +
        this.state.granularity,
      {
        method: "GET",
        headers: {
          Authorization: "JWT " + this.getJWTToken()
        }
      }
    )
      .then(response => response.json())
      .then(json => {
        if (json) {
          this.setState({
            uniqueGraph: this.formatDataTotal(json.total),
            timeGraph: this.formatDataTime(json),
            osGraph: this.formatDataOS(json.osDistrib),
            hoursGraph: this.formatDataHours(json.openDistrib),
            mapPoints: json.openMap
          });
        }
      })
      .catch(err => {
        this.setState({ error: true, errorMessage: err.error });
      });
  }

  _getFormatBackend(granularity) {
    let format = "Y-MM";

    switch (granularity) {
      case "hoursAggreg":
        format = "HH";
        break;

      case "hour":
        format = "YYYY-MM-DD-HH";
        break;

      case "day":
        format = "Y-MM-DD";
        break;

      case "week":
        format = "Y-ww";
        break;

      case "month":
        format = "Y-MM";
        break;

      default:
        break;
    }

    return format;
  }

  _getFormat(granularity) {
    let format = "Y-MM";

    switch (granularity) {
      case "hoursAggreg":
        format = "HH";
        break;

      case "hour":
        format = "YYYY-MM-DD HH:mm";
        break;

      case "day":
        format = "Y-MM-DD";
        break;

      case "week":
        format = "Y-MM-DD";
        break;

      case "month":
        format = "Y-MM";
        break;

      default:
        break;
    }

    return format;
  }
}

const MapPreview = withScriptjs(
  withGoogleMap(props => (
    <GoogleMap defaultZoom={7} defaultCenter={{ lat: 50.8505, lng: 4.3517 }}>
      <MarkerClusterer averageCenter enableRetinaIcons gridSize={60}>
        {props.points &&
          props.points.map(marker => (
            <Marker
              key={marker._id}
              position={{ lat: marker.openPos[1], lng: marker.openPos[0] }}
            />
          ))}
      </MarkerClusterer>
    </GoogleMap>
  ))
);
